
import { mapState } from 'vuex'
export default {
    name: 'Agent',
    middleware: ['auth'],
    data() {
        return {
            title: 'Control Panel',
            drawer: true,
            rightDrawer: false,
            notifications: [],
            depositNotifications: [],
            withdrawalNotifications: [],
            items: [
                {
                    icon: 'mdi-finance',
                    title: 'finance',
                    parent: 'finance',
                    is_done: true,
                    children: [
                        {
                            title: 'deposit',
                            child: 'deposit',
                            code: 'deposit',
                            to: '/finance/deposits',
                        },
                        {
                            title: 'withdrawal',
                            child: 'withdrawal',
                            code: 'withdrawal',
                            to: '/finance/withdrawals',
                        },
                        {
                            icon: 'mdi-credit-card-multiple',
                            title: 'manualAdj',
                            code: 'manual_adjustment',
                            to: '/finance/manual-adjustment',
                        },
                    ],
                },
                {
                    icon: 'mdi-account-multiple',
                    title: 'playerManagement',
                    code: 'player_management',
                    is_done: true,
                    children: [
                        {
                            title: 'players',
                            code: 'player_list',
                            to: '/players/players',
                        },
                        {
                            title: 'onlinePlayers',
                            code: 'online_players',
                            to: '/players/online-players',
                        },
                        {
                            title: 'missingPlayers',
                            code: 'missing_players',
                            to: '/players/missing-players',
                        },
                        {
                            title: 'message',
                            code: 'player_message',
                            to: '/players/messages',
                        },
                    ],
                },
                {
                    icon: 'mdi-swap-horizontal',
                    title: 'bet',
                    code: 'bet',
                    children: [
                        {
                            title: 'playerBets',
                            code: 'players_bet',
                            to: '/bet/player-bet',
                        },
                        {
                            title: 'betHistory',
                            code: 'bet_history',
                            to: '/bet/bet-history',
                        },
                    ],
                },

                {
                    icon: 'mdi-poll',
                    title: 'report',
                    code: 'report',
                    done_soon: true,
                    children: [
                        {
                            title: 'allSummary',
                            code: 'all_summary',
                            to: '/reports/report',
                        },
                        {
                            title: 'depositSummary',
                            code: 'deposit_summary',
                            to: '/reports/deposit',
                        },
                        {
                            title: 'withdrawalSummary',
                            code: 'withdrawal_summary',
                            to: '/reports/withdrawal',
                        },
                    ],
                },
                {
                    icon: 'mdi-account-group',
                    title: 'users',
                    code: 'admin_management',
                    is_done: true,
                    children: [
                        {
                            title: 'systemUsers',
                            code: 'admin_list',
                            to: '/users/users',
                        },
                        {
                            title: 'userActivity',
                            code: 'admin_activity',
                            to: '/users/user-activity',
                        },
                    ],
                },
            ],
        }
    },
    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light'
        },
        isDarkMode: {
            get() {
                return this.$cookie.get('darkMode') || this.$vuetify.theme.dark
            },
            set() {
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark
                this.$cookie.set('darkMode', this.$vuetify.theme.dark)
            },
        },
        availableLocales() {
            return this.languages.filter(
                (language) => language.code !== this.systemLocale
            )
        },
        ...mapState({
            languages: (state) => state.master.languages,
            newDepositList: (state) => state.finance.newDepositsForNotification,
            newWithdrawalList: (state) =>
                state.finance.newWithdrawalsForNotification,
        }),
    },
    watch: {
        newDepositList(value) {
            if (value?.data && value?.data?.length > 0) {
                const notificationArr = value.data.map((el) => {
                    return {
                        color: 'success',
                        icon: 'mdi-arrow-down',
                        link: '/finance/deposits', // + msg.deposit_id,
                        long: '1s',
                        view: false,
                        deposit_id: el.id,
                        player_id: el.player_id,
                        player_name: el.name,
                        agent_uuid: el.agent_username,
                        amount: el.deposit_amount,
                        currency: el.currency,
                        status: el.deposit_status,
                        transfer_type: el.deposit_type,
                        notify_date: el.created_at,
                    }
                })
                this.depositNotifications = []
                this.depositNotifications = [...notificationArr]
            } else {
                this.depositNotifications = []
            }
        },
        newWithdrawalList(value) {
            if (value?.data && value?.data?.length > 0) {
                const notificationArr = value.data.map((el) => {
                    return {
                        color: 'error',
                        icon: 'mdi-arrow-up',
                        link: '/finance/withdrawals', // + msg.deposit_id,
                        long: '1s',
                        view: false,
                        deposit_id: el.id,
                        player_id: el.player_id,
                        player_name: el.name,
                        agent_uuid: el.agent_username,
                        amount: el.withdrawal_amount,
                        currency: el.from_c,
                        status: el.withdrawal_status,
                        transfer_type: el.withdrawal_type,
                        notify_date: el.created_at,
                    }
                })
                this.withdrawalNotifications = []
                this.withdrawalNotifications = [...notificationArr]
            } else {
                this.withdrawalNotifications = []
            }
        },
    },
    created() {
        this.$vuetify.theme.dark = this.$cookie.get('darkMode') || false
    },
    async mounted() {
        // set default currency
        const cookieCurrency = this.$cookie.get('currency')
        if (cookieCurrency) {
            this.$store.commit(
                'settings/setGlobalSelectedCurrency',
                cookieCurrency
            )

            if (cookieCurrency === 'USDT') {
                this.$cookie.set('currency_type', 2)
            } else {
                this.$cookie.set('currency_type', 1)
            }
        }

        this.switchLanguage(null)
        await this.$store.dispatch('master/languages')
        await this.$store.dispatch(
            'master/translationStrings',
            this.systemLocale
        )
        // Socket initiating
        this.socket = await this.$nuxtSocket({
            reconnection: true,
            upgrade: false,
            auth: {
                Authorization: `Bearer ${this.$cookie.get('token')}`,
            },
            transports: ['websocket'],
        })
        this.socket.on('disconnect', (e) => {
            // for  bank testing
            this.$store.commit('external-data/setSocket', 'disconnect')
            setTimeout(() => {
                this.socket.connect()
            }, 5000)
        })
        let reconnectCount = 0
        this.socket.on('connect_error', (e) => {
            if (reconnectCount < 3) {
                setTimeout(() => {
                    this.socket.connect()
                }, 5000)
                reconnectCount++
            }
        })
        const agentUuid = this.$cookie.get('user')?.agent_uuid
        // Subscribe Store, Socket Only
        this.$store.subscribeAction((mutation, state) => {
            // Confirm Deposit
            if (mutation.type === 'sockets/confirmDeposit') {
                const msg = mutation.payload
                const userSelectedCurrency =
                    this.globalSelectedCurrency?.toLowerCase()
                const depositCurrency = msg?.currency?.toLowerCase()

                // msg.agent_uuid === agentUuid &&
                if (userSelectedCurrency === depositCurrency) {
                    this.playNotification(this.tl('newDepositRequest'))
                    this.fetchDepositForNotificationList()
                }
            } else if (mutation.type === 'sockets/createWithdrawal') {
                const msg = mutation.payload
                const userSelectedCurrency =
                    this.globalSelectedCurrency?.toLowerCase()
                const depositCurrency = msg?.currency?.toLowerCase()
                if (
                    msg.agent_uuid === agentUuid &&
                    userSelectedCurrency === depositCurrency
                ) {
                    this.playNotification(this.tl('newWithdrawalRequest'))
                    this.fetchWithdrawalForNotificationList()
                }
            } else if (
                mutation.type === 'sockets/lockDeposit' ||
                mutation.type === 'sockets/unlockDeposit' ||
                mutation.type === 'sockets/revokeDepositByAutoTopup'
            ) {
                this.fetchDepositForNotificationList()
            } else if (
                mutation.type === 'sockets/declinedWithdrawalByPlayer' ||
                mutation.type === 'sockets/lockWithdrawal' ||
                mutation.type === 'sockets/unlockWithdrawal'
            ) {
                this.fetchWithdrawalForNotificationList()
            }
        })
    },
    methods: {
        logout() {
            if (confirm(this.tl('areYouSure'))) {
                this.$store.dispatch('auth/logout')
            }
        },
        connectAgain() {
            this.socket.emit('message', 'clicked-again')
        },
        getCurrentLocale(localeCode) {
            if (!localeCode) return null
            const currentLocaleData = this.languages.find(
                (el) => el.code === localeCode
            )
            if (!currentLocaleData) return null
            return currentLocaleData.flag_icon
        },
        switchLanguage(code) {
            if (code) {
                this.$cookie.set('locale', code)
                window.location.reload()
            } else {
                this.$cookie.set(
                    'locale',
                    this.$cookie.get('locale') ?? 'en-US'
                )
                if (!this.$cookie.get('locale')) {
                    window.location.reload()
                }
            }
        },
        async onSwitchLocale(locale) {
            const data = {
                language: locale.code,
            }
            this.$store.commit('master/loadingStringsForTranslation', true)
            await this.$store.dispatch('master/translationStrings', locale.code)
            this.$store.commit('master/loadingStringsForTranslation', false)
            this.$store.dispatch('users/updateUser', data)
            this.$cookie.set('locale', locale.code)
            this.$store.dispatch('settings/locale', locale.code)
        },
        pushNotification(e) {
            this.notifications.unshift(e)
        },
        playNotification(toastText) {
            this.$toast.info(toastText)
            if (this.$cookie.get('sound')) {
                const audio = new Audio('/sounds/notification.mp3')
                audio.play()
                setTimeout(() => {
                    audio.play()
                }, 3000)
            }
        },
        removeItem(index) {
            this.$delete(this.notifications, index)
        },
        toggleDropdown(toggle) {
            if (toggle) {
                for (const notify of this.notifications) {
                    notify.long = this.calculateTime(notify.notify_date)
                }
            }
        },
        calculateTime(dateTime) {
            const duration = this.$moment.duration(
                this.$moment().diff(this.$moment(dateTime))
            )
            const year = Math.floor(duration.asYears())
            if (year) return year + 'Y'
            const month = Math.floor(duration.asMonths())
            if (month) return month + 'M'
            const week = Math.floor(duration.asWeeks())
            if (week) return week + 'W'
            const day = Math.floor(duration.asDays())
            if (day) return day + 'D'
            const hour = Math.floor(duration.asHours())
            if (hour) return hour + 'H'
            const minute = Math.floor(duration.asMinutes())
            if (minute) return minute + 'm'
            const second = Math.floor(duration.asSeconds())
            return second + 's'
        },
        fetchDepositForNotificationList() {
            this.$store.dispatch('finance/newDeposits', {
                sort: 'created_at|desc',
                limit: 10,
                page: 1,
                start_date: this.$moment().format('YYYY-MM-DD') + ' 00:00:00',
                end_date: this.$moment().format('YYYY-MM-DD') + ' 23:59:59',
                search_type: 1, // 1  createdTime, 2 updatedTime
                currency: this.globalSelectedCurrency,
                for_notifications: true,
            })
        },
        fetchWithdrawalForNotificationList() {
            this.$store.dispatch('finance/newWithdrawals', {
                sort: 'created_at|desc',
                limit: 10,
                page: 1,
                start_date: this.$moment().format('YYYY-MM-DD') + ' 00:00:00',
                end_date: this.$moment().format('YYYY-MM-DD') + ' 23:59:59',
                search_type: 1, // 1  createdTime, 2 updatedTime
                currency: this.globalSelectedCurrency,
                for_notifications: true,
            })
        },
    },
}
