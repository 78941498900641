import { routes } from "./routes-constants"

const NO_PERMISSION = 'You don\'t have permissions'

function accessObject(value) {
	const keys = value.split('.');
	let current = routes;
	for (const key of keys) {
		if (current[key]) {
			current = current[key];
		} else {
			return undefined;
		}
	}
	return current;
}

// Dynamic Routes, path/*
const removeAgentPrefix = [
	'/language-strings/language/',
	'/statistics/'
]

export default function (context) {
	const uri = {}
	const isAgentWebsite = context.$cookie.get('ia')
	// check the user permissions, if return false, stop sending request to server
	const checkUserPermission = (type, url) => { // type: create, delete, update, view
		url = url.replace('https://api.cslvault.com/cms/bo', '')

		// if the user is Super Admin, always return true since Super Admin can do any the system
		if ((context.$cookie?.get('user') && context.$cookie?.get('user').user_type === 1) || !context.$cookie?.get('token')) return true
		// for update user profile
		if (url === '/users') return true
		if (url === 'users/me/info') return true
		if (url === '/users/session/logout') return true
		if (type === 'get' && (url === '/users/permissions' || url === '/agents/permissions' || url.includes('/language-strings') || url === '/currency') || url.includes('/languages') || url.includes('/master') || url.includes('csl-master/master')) {
			return true;
		}
		const userPermissions = context.store.state.settings.userPermissions
		const currentPermissionCode = context.store.state.settings.currentPermissionCode
		// getting my user permissions object
		const permissionsByCurrentPermissionCode = userPermissions.find(el => el.code === currentPermissionCode);
		if (!permissionsByCurrentPermissionCode) {
			if (url === '/users/permissions' || url === '/agents/permissions' || url.includes('/language-strings') || url.includes('login') || url.includes('verify-tfa-otp') || url === '/currency' || url === '/users/session/logout' || url === '/users' || url === 'users/me/info') {
				return true;
			}
			return false
		}

		if (type === 'get') return permissionsByCurrentPermissionCode.can_view;
		if (type === 'post') return permissionsByCurrentPermissionCode.can_create
		if (type === 'delete') return permissionsByCurrentPermissionCode.can_delete
		if (type === 'put') return permissionsByCurrentPermissionCode.can_update
	}
	// converting local time to UTC time
	const utcTime = (date) => {
		if (date) {
			return context.$moment(date).utc().format('YYYY-MM-DD HH:mm:ss')
		}
		return context.$moment().utc().format('YYYY-MM-DD HH:mm:ss')
	}
	context.$axios.onRequest((config) => {
		// Dynamic routes path for user and agents
		// Getting paths
		const result = accessObject(config.url)
		let routeUrl = null;

		// Check if the route are route
		const checkIfTheUrlIsTheExceptionalRoutes = removeAgentPrefix.find((el) => {
			if (config.url.startsWith(el)) return true;
			return false;
		})
		if (!checkIfTheUrlIsTheExceptionalRoutes) {
			if (isAgentWebsite) {
				// If the website is agent website, then
				// 1. Make agent paths as default path if it's existing,
				// 2. Make user paths as default if agent path is not exist
				// 3. If neither agent and user paths are not exists, use default path as default
				if (!result) {
					// If routes is not exist, then add prefix
					if (config?.url?.startsWith('/')) {
						routeUrl = '/agents' + config.url
					} else {
						routeUrl = '/agents/' + config.url
					}
				} else if (result?.agent) routeUrl = result.agent
				else if (result?.user) routeUrl = result.user
			} else routeUrl = result?.user

			if (routeUrl) config.url = routeUrl
		}


		uri.url = config.url
		uri.params = config.params
		uri.domain = context.$config.API
		uri.method = config.method
		uri.username = config?.data?.username
		uri.data = config?.data
		uri.fullPath = context.route.fullPath

		if (process.client) {
			/**
			 * production mode
			 */
			config.headers.common['agent-domain'] = window?.location?.host
			/**
			 * development mode
			 */
			// config.headers.common['agent-domain'] = 'office.wing365.online'
			// config.headers.common['agent-domain'] = 'office.ddbet99.com'
		}
		const params = { ...config.params } || {}
		// This param is for DateButtonFilters Component purpose only
		if (params.selectedDate) params.selectedDate = null

		// Check if the request have dates
		if (params.start_date) params.start_date = utcTime(params.start_date)
		if (params.end_date) params.end_date = utcTime(params.end_date)
		// What is keepCurrency for?
		// Don't use the global currency
		if (params.currency && !params.keepCurrency) params.currency = context.store.state.settings.globalSelectedCurrency
		if (params?.notFire) delete params.notFire


		// To remove null values from parameter
		for (const [key, value] of Object.entries(params)) {
			if (value || value === false) {
				config.params[key] = value;
			} else {
				delete config.params[key]
			}
		}
		if (params?.keepCurrency) delete config.params.keepCurrency
		if (params?.notFire || params?.notFire === false) delete config.params.notFire
		if (checkUserPermission(config.method, config.url) === false) {
			throw new Error(NO_PERMISSION) // Stop processing the request
		}
		config.baseURL = context.app.$config.API
		config.headers.common.Authorization = `Bearer ${context.$cookie?.get('token') || context.$cookie?.get('agentToken') || context.$cookie?.get('affiliateToken')}`
		if (context.$cookie?.get('currency')) {
			config.headers.common.currency = `${context.$cookie?.get('currency')}`
		}
	})

	// Check if response is in JSON.
	context.$axios.interceptors.response.use(response => {
		const isExist = response.headers['content-type']?.indexOf('application/json');
		return isExist !== -1 ? response : Promise.reject(response);
	}, error => Promise.reject(error));

	// Error handling
	context.$axios.onError((error) => {
		// Send notification to SLACK
		const stackTitle = `*<https://office.${context.$config.LANDING_DOMAIN}|${context.$config.LANDING_DOMAIN}> - ${error?.response?.data?.message || error?.message === NO_PERMISSION && 'No permission'}`
		const stackBody = `[${uri.method?.toUpperCase()}|${error.response?.status}] ${uri.domain}${(uri.url)} \n *Time:* ${context.$moment().format("YYYY-MM-DD HH:mm:ss")}\n*Username:* ${context?.$cookie?.get('user')?.username || uri.username || ''}\n*Payload:*\n${uri.params ? JSON.stringify(uri.params) : uri.data ? JSON.stringify(uri.data) :
			'-'}\n *Full Path:* \n${uri.fullPath}`

		//   Once user Unauthorized then moved them to login page.
		if (error.response?.status === 401) {
			if (context.$cookie?.get('token')) {
				context.$cookie.remove('token')
				context.app.router.push('/login')
			} else if (context.$cookie?.get('agentToken') || context.$cookie?.get('affiliateToken')) {
				context.$cookie.remove('agentToken')
				context.$cookie.remove('affiliateToken')
				context.app.router.push('/agent')
			} else if (window.location.pathname.split('/')[1] === 'agent') {
				context.$cookie.remove('agentToken')
				context.$cookie.remove('affiliateToken')
				context.app.router.push('/agent')
			} else {
				context.$cookie.remove('token')
				context.app.router.push('/login')
			}
			// context.$cookie.remove('token')
			// context.app.router.push('/login')
		}

		// Clear old toast before showing the new one
		context.$toast.clear()

		// Why not putting this toast in the THROW ERROR case, because I need toast.clear() comes after this toast
		if (error?.message === NO_PERMISSION) {
			context.$toast.error(NO_PERMISSION)
			context.store.dispatch('settings/redirectToHasPermissionRoute')
		} else if (error?.response && error?.response?.data?.message) {
			// This try catch is used in case the language is not really loaded
			// And sometimes it gets
			if (!context.$config.DEVELOPMENT) {
				context.$sendSlack(stackTitle, stackBody)
			}
			try {
				const languageStrings = context.store.state.master.translationString
				const text = languageStrings[error.response.data.message]

				if (text) {
					context.$toast.error(text)
				} else {
					context.$toast.error(error.response.data.message)
				}
			} catch (error) { }
		} else if (error.message !== '') {
			if (!context.$config.DEVELOPMENT) {
				context.$sendSlack(stackTitle, stackBody)
			}
			context.$toast.error('Try again after some time!')
		}
		if (error.response?.status === 403) {
			const userData = context.$cookie.get('user') || {}
			userData.user_type = 2
			context.$cookie.set('user', userData)
			context.store.dispatch('settings/redirectToHasPermissionRoute')
		}
		return false
	})
}